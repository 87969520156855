import React from 'react';
import Helmet from 'react-helmet';
import Div100vh from 'react-div-100vh';

import Layout from '../components/Layout/Layout';
import { useSiteMetadata } from '../hooks';
import TransitionLink from '../components/TransitionLink/TransitionLink';

const NotFound = () => {
	const { name } = useSiteMetadata();

	return (
		<Layout>
			<Helmet title={`404 | ${name}`} />
			<Div100vh className='p-not-found'>
				<h2 className='u-heading-xlarge'>404</h2>
				<h3 className='u-heading-large'>Page not found</h3>
				<TransitionLink to='/'>Take me back</TransitionLink>
			</Div100vh>
		</Layout >
	);
};

export default NotFound;
